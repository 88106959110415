// data
// const promotions = [
//   {
//     first: "-20%",
//     main: "Pobierz aplikację KIMIDORI Green Sushi. Zyskaj -20% na pierwsze zamówienie i korzystaj z kolejnych zniżek. Teraz możesz zamawiać z dwóch siostrzanych restauracji jednocześnie, przy jednej dostawie.",
//     link: "link.pl",
//   },
// ];

const promotions = [
  {
    first: "-20%",
    main: "Pobierz aplikację KIMIDORI. Zyskaj -20% na pierwsze zamówienie*  i korzystaj z kolejnych zniżek.",
    description:
      "*Rabat jest naliczany po złożeniu zamówienia, nie obejmuje oferty lunchowej i napojów, nie łączy się z innymi rabatami i promocjami. Nie obowiązuje 31.12 oraz 14.02.",
    link: "https://www.kimidori.pl/menu",
  },

  {
    first: "-40%",
    main: "-40% na kategorię BESTSELLERY",
    description:
      "Wybierz porcje z kategorii Bestsellery, użyj kodu GREEN40 i zyskaj aż 40% rabatu na całą grupę Bestsellerów. Promocja obowiązuje przy zamówieniach online.",
    link: "https://www.kimidori.pl/menu",
  },
  {
    first: "10zł = 1punkt",
    main: "Program lojalnościowy.",
    description:
      "Zbieraj punkty i wymieniaj na nagrody. Za każde wydane 10 zł w zamówieniu online otrzymujesz 1 punkt. Punkty naliczają się tylko zarejestrowanym użytkownikom, po każdym złożonym zamówieniu. Ilość uzbieranych punktów widoczna będzie po zalogowaniu, w zakładce moje konto. Po zgromadzeniu odpowiedniej ilości punktów, dodaj wybraną nagrodę do koszyka.",
    link: "https://www.kimidori.pl/menu",
  },
];

export default promotions;
