import React, { useEffect, useState } from "react";

import styled from "styled-components";
import distances from "src/assets/styles/distances";
import colors from "src/assets/styles/colors";
import typographySizes from "src/assets/styles/typographySizes";
import mediaQuery from "src/assets/styles/mediaQuery";
import { Link } from "gatsby";
import ContextConsumer from "src/layouts/Context.js";
import SecNav from "src/components/global/secNav/SecNav.js";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";

import bgImg from "src/assets/images/img/header.png";

import Title from "src/components/typography/Title.js";
import SubTitle from "src/components/typography/SubTitle.js";
import MainWrapper from "src/components/global/MainWrapper.js";
import mianInfo from "src/assets/data/mainInfo.js";
import fb from "src/assets/images/svg/fb_header.svg";
import instagram from "src/assets/images/svg/insa_header.svg";
import logo from "src/assets/images/svg/Logo.svg";
import logoWhite from "src/assets/images/svg/Logo-white.svg";
import leafNav from "src/assets/images/svg/leaf_nav.svg";
import Hamburger from "src/components/global/Hamburger.js";

import Something from "src/assets/images/svg/leaf.inline.svg";
import Div100vh from "react-div-100vh";

import menuPdf from "src/assets/menu.pdf";

const HeaderWrapper = styled.div`
  height: 100vh;
  @media (max-width: ${mediaQuery.tablet}) {
    height: initial;
  }
`;

const StyledMainWrapper = styled(MainWrapper)`
  background-color: ${colors.pink};
  display: flex;
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
  }
`;

const ImgWrapper = styled.div`
  width: calc(41.66% + ${distances.gap}rem);
  height: 100%;
  background-image: url(${bgImg});
  background-size: cover;
  background-position: center;
  position: relative;
  margin-left: -${distances.gap}rem;

  @media (max-width: ${mediaQuery.tablet}) {
    width: calc(100% + ${distances.gap}rem);
    height: 300rem;
    margin-left: -${distances.gap / 2}rem;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    /* height: 50%; */
  }
`;

// const Leaf = styled.img`
//   position: absolute;
//   bottom: 0;
//   right: 10%;
// `;

const MainCol = styled.div`
  width: 58.33%;
  position: relative;
  @media (max-width: ${mediaQuery.tablet}) {
    width: 100%;
    height: 55%;
  }
  @media (max-width: ${mediaQuery.mobile}) {
    height: 70%;
  }
  .leaf {
    position: absolute;
    bottom: 0;
    right: 10%;
    width: 350rem;
  }
`;

const MainTextWrapper = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  margin-left: ${distances.gap}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    transform: translateY(0%);
    position: relative;
    top: 0rem;
    margin-left: 0;
    margin-top: 50rem;
  }
`;

const HeaderSubTitle = styled(SubTitle)`
  margin-top: 30rem;

  max-width: 600rem;
  span {
    font-size: ${typographySizes.s}rem;
    font-family: inherit;
    color: inherit;
  }
`;

const BottomHeader = styled.div`
  position: absolute;
  bottom: 80rem;
  margin-left: ${distances.gap}rem;
  width: calc(100% - ${distances.gap}rem);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: ${mediaQuery.tablet}) {
    position: relative;
    flex-direction: column;
    align-self: start;
    align-items: flex-start;
    bottom: 0;
    margin-top: 0rem;
    width: 100%;
    margin-left: 0;
  }
`;

const OrderButton = styled.a`
  font-size: ${typographySizes.m}rem;
  font-weight: 400;
  line-height: 1.1;
  color: ${colors.light};
  font-family: "sofia-pro", sans-serif;
  cursor: pointer;
  position: relative;
  transform: translateY(0rem);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  display: inline-block;
  margin-right: 40rem;
  ::before {
    content: "";
    position: absolute;
    bottom: -4rem;
    left: 0;
    width: 100%;
    height: 1rem;
    background-color: ${colors.light};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateY(0rem);
  }
  &:hover {
    transform: translateY(-10rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    ::before {
      transform: translateY(10rem);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    font-size: ${typographySizes.s}rem;
    margin-top: 40rem;
  }
`;

const SocialWrapper = styled.div`
  display: flex;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-top: 40rem;
    margin-bottom: 20rem;
  }
`;

const SocialLink = styled.a`
  margin-left: ${distances.gap / 2}rem;
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
    margin-right: ${distances.gap / 2}rem;
  }
`;

const NavWrapper = styled.nav`
  position: absolute;
  top: ${distances.gap}rem;
  left: calc(41.66% + ${distances.gap}rem);
  margin-right: ${distances.gap}rem;
  width: calc(58.33% - ${distances.gap * 2}rem);
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;
  @media (max-width: ${mediaQuery.desktop}) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    position: fixed;
    left: 0;
    width: calc(100% - ${distances.gap}rem);
    flex-direction: row;
    justify-content: space-between;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

    padding: 0 ${distances.gap / 2}rem;
    /* ::before {
      background: linear-gradient(rgb(0, 0, 0) 100%, rgba(0, 0, 0, 0) 0%);
      content: "";
      position: absolute;
      height: 100rem;
      left: 0;
      top: 0;
      width: 100%;
    } */
    &.scrolled {
      background-color: ${colors.green};
      top: 0;
      padding: 10rem ${distances.gap / 2}rem;

      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const Logo = styled.img`
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  width: 150rem;
  &.hide {
    opacity: 0;
    position: absolute;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  &.show {
    opacity: 1;
    position: relative;
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
  @media (max-width: ${mediaQuery.tablet}) {
    width: 130rem;
  }
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: ${mediaQuery.desktop}) {
    margin-top: 20rem;
  }
  @media (max-width: ${mediaQuery.tablet}) {
    flex-direction: column;
    width: calc(100% - ${distances.gap}rem - 80rem);
    background-color: ${colors.green};
    position: fixed;
    padding: 40rem;
    align-items: flex-end;
    height: auto;
    overflow: hidden;
    margin-top: 0;
    top: 130rem;

    clip-path: polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    pointer-events: none;
    &.open {
      clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
      pointer-events: auto;
    }
  }
`;

const NavLink = styled(ScrollLink)`
  font-size: ${typographySizes.s}rem;
  color: ${colors.light};
  font-weight: 400;
  font-family: "sofia-pro", sans-serif;
  margin-left: ${distances.gap / 2}rem;
  cursor: pointer;
  @media (max-width: ${mediaQuery.desktop}) {
    :first-of-type {
      margin-left: 0;
    }
  }
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
    padding: 20rem 0;
    font-size: ${typographySizes.m}rem;
  }
  transform: translateY(0rem);
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  &:hover {
    transform: translateY(-5rem);
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
  }
`;

const NavLinkMenu = styled.a`
  font-size: ${typographySizes.s}rem;
  color: ${colors.light};
  font-weight: 400;
  font-family: "sofia-pro", sans-serif;
  margin-left: ${distances.gap / 2}rem;
  position: relative;
  cursor: pointer;
  transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);

  /* ::before {
    content: "";
    position: absolute;
    bottom: -4rem;
    left: 0;
    width: 100%;
    height: 1rem;
    background-color: ${colors.light};
    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    transform: translateY(0rem);
  } */
  @media (max-width: ${mediaQuery.tablet}) {
    margin-left: 0;
    font-size: ${typographySizes.m}rem;
    display: inline-block;
    flex-grow: 0;

    padding: 20rem 0;
    ::before {
      bottom: 16rem;
    }
  }
  &:hover {
    transform: translateY(-5rem);

    transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    ::before {
      bottom: -9rem;
      transition: all 0.5s cubic-bezier(0.65, 0, 0.35, 1);
    }
  }
`;

const NavLeaf = styled.img`
  display: none;
  @media (max-width: ${mediaQuery.tablet}) {
    display: block;
    position: absolute;
    top: 0;
    left: 20rem;
    width: 30vw;
  }
`;

const InfoBox = styled.div`
  padding: 30rem;
  position: absolute;
  bottom: 30rem;
  left: 30rem;
  background-color: ${colors.light};
  width: calc(100% - 120rem);
  p {
    font-size: 20rem;
    font-family: "sofia-pro", sans-serif;
    color: ${colors.green};
    font-weight: 400;
    @media (max-width: ${mediaQuery.tablet}) {
      font-size: 16rem;
    }
  }
`;

const Header = () => {
  if (typeof window !== "undefined") {
    require("smooth-scroll")('a[href*="#"]');
  }
  const isBrowser = typeof window !== "undefined";

  const [navScrolled, setNavScrolled] = useState(false);

  useEffect(() => {
    if (isBrowser) {
      window.onscroll = function (e) {
        let scrollTop =
          window.pageYOffset ||
          (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;

        scrollTop > 150 ? setNavScrolled(true) : setNavScrolled(false);
      };
    }
  }, []);

  return (
    <HeaderWrapper>
      <SecNav scrolled={navScrolled} />
      <StyledMainWrapper>
        <ImgWrapper>
          {/* <InfoBox>
            <p>
              W dniach 29.08 - 02.09 KIMIDORI Green Sushi i Pink Vegan będą
              nieczynne. Przenosimy się do nowej lokalizacji na warszawskie
              Powiśle. Zapraszamy na ul. Browarną 6 lok. 4a już w sobotę 03.09.
              Do zobaczenia.
            </p>
          </InfoBox> */}
        </ImgWrapper>
        <MainCol>
          {/* <Leaf src={leaf} /> */}
          <Something className="leaf" />
          <MainTextWrapper>
            <Title
              data-sal="slide-up"
              data-sal-delay="0"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              Pełna smaku kuchnia wegańska
            </Title>
            <HeaderSubTitle
              data-sal="slide-up"
              data-sal-delay="200"
              data-sal-easing="ease"
              data-sal-duration="500"
            >
              Odwiedź naszą restaurację na warszawskim Powiślu lub zamów z
              dostawą do domu <br /> <br />
              <span>
                Teraz możesz zamawiać z dwóch siostrzanych restauracji
                jednocześnie, przy jednej dostawie
              </span>
            </HeaderSubTitle>
          </MainTextWrapper>

          <BottomHeader>
            <div>
              <OrderButton
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="500"
                target="_blank"
                href="https://www.kimidori.pl/menu"
              >
                zamów online
              </OrderButton>
              <OrderButton
                data-sal="slide-up"
                data-sal-delay="400"
                data-sal-easing="ease"
                data-sal-duration="500"
                target="_blank"
                href="https://kimidori.upmenusite.com/"
              >
                dokonaj rezerwacji
              </OrderButton>
            </div>
            <SocialWrapper>
              <SocialLink
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-easing="ease"
                data-sal-duration="500"
                href={mianInfo.facebook}
                target="_blank"
              >
                <img src={fb} alt="facebook icon" />
              </SocialLink>
              <SocialLink
                data-sal="slide-up"
                data-sal-delay="800"
                data-sal-easing="ease"
                data-sal-duration="500"
                href={mianInfo.instagram}
                target="_blank"
              >
                <img src={instagram} alt="facebook icon" />
              </SocialLink>
            </SocialWrapper>
          </BottomHeader>
        </MainCol>
        <ContextConsumer>
          {({ data, set }) => (
            <NavWrapper className={navScrolled && "scrolled"}>
              <Logo
                className={navScrolled ? "hide" : "show"}
                src={logo}
                alt="Pink Vegan Logo"
              />
              <Logo
                className={navScrolled ? "show" : "hide"}
                src={logoWhite}
                alt="Pink Vegan Logo"
              />
              <Hamburger />
              <NavLinks className={data.navOpen && "open"}>
                <NavLeaf src={leafNav} />
                <NavLink
                  onClick={() => {
                    // set({ nav: true });
                    data.navOpen
                      ? set({ navOpen: false })
                      : set({ navOpen: true });
                  }}
                  to="onas"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  o nas
                </NavLink>
                <NavLink
                  onClick={() => {
                    // set({ nav: true });
                    data.navOpen
                      ? set({ navOpen: false })
                      : set({ navOpen: true });
                  }}
                  to="promocje"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  promocje
                </NavLink>
                <NavLink
                  onClick={() => {
                    // set({ nav: true });
                    data.navOpen
                      ? set({ navOpen: false })
                      : set({ navOpen: true });
                  }}
                  to="dostawa"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  dostawa
                </NavLink>
                <NavLink
                  onClick={() => {
                    // set({ nav: true });
                    data.navOpen
                      ? set({ navOpen: false })
                      : set({ navOpen: true });
                  }}
                  to="kontakt"
                  activeClass="active"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  kontakt
                </NavLink>
                <NavLinkMenu href={menuPdf} target="_blank">
                  pobierz pełne menu
                </NavLinkMenu>
                <NavLinkMenu
                  href="https://www.kimidori.pl/menu"
                  target="_blank"
                >
                  zamów online
                </NavLinkMenu>
              </NavLinks>
            </NavWrapper>
          )}
        </ContextConsumer>
      </StyledMainWrapper>
    </HeaderWrapper>
  );
};

export default Header;
